<template>
  <section :class="$customSectionClasses(props.cfgs)">
    <div class="container">
      <div
        class="grid-information-cards"
        :class="{'single-row': Boolean((values?.items || []).length <= 3)}"
      >
        <template v-if="values?.items?.length">
          <div
            v-for="(item, index) in values.items"
            :key="`item-${index}`"
            class="grid-information-card"
            :class="getClassGridCard(index)"
          >
            <div
              v-if="item.title"
              class="grid-information-card__title"
              v-html="item.title"
            />
            <div
              v-if="item.message"
              class="grid-information-card__message cms-block-stub"
              v-html="item.message"
            />
            <a
              v-if="item.link"
              :href="item.link"
              class="btn primary grid-information-card__button"
              target="_blank"
            >
              {{ item.text_button || 'Перейти' }}
            </a>
            <client-only>
              <nuxt-img
                placeholder
                alt="img"
                :src="String(item.image)"
                :height="getImageParamsByKey(item, 'height', DEFAULT_IMAGE_HEIGHT)"
                :width="getImageParamsByKey(item, 'width')"
                fit="crop"
                loading="lazy"
                class="grid-information-card__image"
              />
            </client-only>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { SectionTypes } from '~/plugins/customSectionClasses';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import getImageParamsByKey from "~/utils/images/getImageParamsByKey";

const { $customSectionClasses } = useNuxtApp();
const DEFAULT_IMAGE_HEIGHT = 560

type CardItemType = {
  image: number;
  link: string;
  message: string;
  text_button: string;
  title: string;
}

type GridInformationCardsBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs'> & {
  values: {
    items: CardItemType[]
  };
  cfgs: SectionTypes;
};

const props = defineProps<GridInformationCardsBlockType>();
const configs = props.cfgs

const blockPositionsGrid: {[key: number]: string[]} = {
  1: [
    'row-start-1 row-span-1 col-span-12'
  ],
  2: [
    'row-start-1 row-span-1 col-span-6',
    'row-start-1 row-span-1 col-span-6'
  ],
  3: [
    'row-start-1 row-span-1 col-span-6',
    'row-start-1 row-span-1 col-span-3',
    'row-start-1 row-span-1 col-span-3'
  ],
  4: [
    'row-start-1 row-span-9 col-span-8',
    'row-start-1 row-span-16 col-span-4',
    'row-start-8 row-span-7 col-span-4',
    'row-start-8 row-span-7 col-span-4'
  ],
  5: [
    'row-start-1 row-span-10 col-span-5',
    'row-start-1 row-span-10 col-span-3',
    'row-start-1 row-span-16 col-span-4',
    'row-start-10 row-span-6 col-span-3',
    'row-start-10 row-span-6 col-span-4'
  ],
  6: [
    'row-start-1 row-span-10 col-span-5',
    'row-start-1 row-span-5 col-span-3',
    'row-start-1 row-span-16 col-span-4 col-start-9',
    'row-start-1 row-span-5 col-span-3 col-start-6',
    'row-start-10 row-span-6 col-span-3',
    'row-start-10 row-span-6 col-span-5'
  ],
  7: [
    'row-start-1 row-span-10 col-span-5',
    'row-start-1 row-span-5 col-span-3',
    'row-start-1 row-span-10 col-span-4 col-start-9',
    'row-start-1 row-span-5 col-span-3 col-start-6',
    'row-start-10 row-span-6 col-span-3',
    'row-start-10 row-span-6 col-span-5',
    'row-start-10 row-span-6 col-span-4'
  ],
  8: [
    'row-start-1 row-span-10 col-span-3',
    'row-start-1 row-span-5 col-span-3',
    'row-start-1 row-span-10 col-span-3 col-start-7',
    'row-start-1 row-span-10 col-span-3 col-start-10',
    'row-start-1 row-span-5 col-span-3 col-start-4',
    'row-start-10 row-span-6 col-span-3',
    'row-start-10 row-span-6 col-span-5',
    'row-start-10 row-span-6 col-span-4'
  ]
};

const getClassGridCard = (index: number) => {
  const setting = blockPositionsGrid[(props?.values?.items || []).length];
  return setting[index];
};
</script>

<style lang="scss">
@import "@/assets/scss/media";

.grid-information-cards-block {
  padding: 60px 0;
  box-sizing: border-box;
}

.grid-information-cards {
  display: grid;
  gap: 16px;
  grid-template-rows: repeat(16, 21px);
  grid-template-columns: repeat(12, minmax(0, 1fr));

  &.single-row {
    grid-template-rows: repeat(1,360px);
  }

  @for $i from 1 through 20 {
    .row-span-#{$i} {
      grid-row: span $i / span $i;
    }
  }
}

.grid-information-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px;
  box-sizing: border-box;
  background: #F5F4F8;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.grid-information-card__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #FFF;

  p {
    margin-bottom: 0;
  }
}

.grid-information-card__message {
  margin-top: 12px;
  color: #FFF;

  p {
    color: #FFF;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.grid-information-card__image {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}

.grid-information-card__button {
  margin-top: 12px;
  font-size: 16px;
}

@include media('xl') {
  .grid-information-cards {
    gap: 10px;
  }

  .grid-information-card {
    padding: 20px;
  }
}

@include media('lg') {
  .grid-information-cards {
    display: flex;
    flex-wrap: wrap;
    gap: initial;
    grid-template-rows: initial;
    grid-template-columns: initial;
    margin-left: -10px;
    margin-top: -10px;
  }

  .grid-information-card {
    margin-left: 10px;
    margin-top: 10px;
    width: calc(50% - 10px);
  }

  .grid-information-card__title {
    font-size: 18px;
    line-height: 24px;
  }
}

@include media('md') {
  .grid-information-cards {
    flex-direction: column;
    margin-left: 0;

    & > * {
      width: 100%;
      margin-left: 0;
    }
  }

  .grid-information-card {
    min-height: 240px;
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/media";

.col-span-1 {
  grid-column: span 1;
}

.col-span-2 {
  grid-column: span 2;
}

.col-span-3 {
  grid-column: span 3 !important;
}

.col-span-4 {
  grid-column: span 4;
}

.col-span-5 {
  grid-column: span 5;
}

.col-span-6 {
  grid-column: span 6;
}

.col-span-7 {
  grid-column: span 7;
}

.col-span-8 {
  grid-column: span 8;
}

.col-span-9 {
  grid-column: span 9;
}

.col-span-10 {
  grid-column: span 10;
}

.col-span-11 {
  grid-column: span 11;
}

.col-span-12 {
  grid-column: span 12;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-start-7 {
  grid-row-start: 7;
}

.row-start-8 {
  grid-row-start: 8;
}

.row-start-9 {
  grid-row-start: 9;
}

.row-start-10 {
  grid-row-start: 10;
}

.row-start-auto {
  grid-row-start: auto;
}

.row-span-1 {
  grid-row-end: span 1;
}

.row-span-2 {
  grid-row-end: span 2;
}

.row-span-3 {
  grid-row-end: span 3;
}

.row-span-4 {
  grid-row-end: span 4;
}

.row-span-5 {
  grid-row-end: span 5;
}

.row-span-6 {
  grid-row-end: span 6;
}

.row-span-full {
  grid-row-end: -1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-start-12 {
  grid-column-start: 12;
}
</style>
